.cnect-tabs {
  position: relative;
  display: inline-flex;

  .tab {
    cursor: pointer;

    &.active {
      color: $color-primary;
      border-bottom: 2px solid $color-secondary;
    }

    &:hover:not(.active) {
      color: $color-primary;
      border-bottom: 2px solid $color-grey-lines;
    }
  }
}
