// Error messages should all be part of a forms input group
// We will have to make sure these global styles are included by default in all templates / themes
// What about form level error messages?   and maybe non input-group related

%transitions-error-messages {
  transition: top 0.25s ease-in-out 0s, opacity 0.25s ease-in-out 0.1s;
}

.input-group {
  .error-message-ani {
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    transition: top 0.25s ease-in-out 0s, opacity 0.25s ease-in-out 0.1s, z-index 0s linear 0.35s;
    opacity: 0;
    line-height: inherit;
    font-size: inherit;

    .error-message {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.25s ease 0s;
      opacity: 0;
      // line-height: 40px;

      &.active {
        transition: opacity 0.25s ease 0.1s;
        opacity: 1;
      }
    }

    &.active {
      z-index: 1;
      top: 48px;
      left: 0;
      opacity: 1;
      @extend %transitions-error-messages;
    }

    &.select {
      top: 59px; // Need to handle this situation better

      &.active {
        top: 77px; // Need to handle this situation better
      }
    }

    &.checkbox {
      top: 36px; // Need to handle this situation better

      &.active {
        top: 24px; // Need to handle this situation better
      }
    }

    &.textarea {
      position: relative;
      top: -15px !important;
    }
  }
}

.form-error-message {
  position: absolute;
  top: 40px;
  opacity: 0;
  font-size: inherit;
  @extend %transitions-error-messages;

  &.active {
    top: 50px;
    opacity: 1;
    @extend %transitions-error-messages;
  }
}

.errors-form-submission {
  position: relative;
  display: block;
  text-align: center;
  margin: 1rem auto 1rem;
  color: $color-error;

  .error-message {
    position: relative;
    top: -20px;
    opacity: 0;
    transition: all 0.2s ease-in-out 0s;
    &.active {
      top: 0;
      opacity: 1;
      transition: all 0.2s ease-in-out 0s;
    }
  }
}

// Sizing
.input-group {
  &.small {
    .error-message-ani {
      top: 11px;

      &.active {
        top: 26px;
      }
    }
  }

  &.large {
    .error-message-ani {
      top: 32px;

      &.active {
        top: 44px;
      }
    }
  }

  &.xlarge {
    .error-message-ani {
      top: 36px;

      &.active {
        top: 50px;
      }
    }
  }

  .error-message-ani {
    top: 24px;

    &.active {
      top: 45px;
    }
  }

  // Style: Material-ish
  &.material {
    &.small {
      .error-message-ani {
        top: 15px;

        &.active {
          top: 28px;
        }
      }
    }

    &.large {
      .error-message-ani {
        top: 30px;

        &.active {
          top: 42px;
        }
      }
    }

    &.xlarge {
      .error-message-ani {
        top: 38px;

        &.active {
          top: 50px;
        }
      }
    }

    .error-message-ani {
      top: 30px;

      &.active {
        top: 40px;
      }
    }
  }
}

.error-message-password-list {
  position: relative;
  margin-bottom: 1rem;

  .error-message {
    display: block;
    color: inherit;
    line-height: 1.5rem;

    i {
      margin-left: 5px;
      opacity: 0;
      transition: opacity 0.4s ease-in-out 0s;
    }

    &.active {
      color: $color-error;
    }

    &.pass {
      color: $color-green;

      i {
        opacity: 1;
        transition: opacity 0.4s ease-in-out 0s;
      }
    }
  }
}
