.cdk-drag-placeholder {
  > div {
    background-color: rgb(240, 240, 240);
    opacity: 0.5;
    border: 1px dashed rgb(165, 165, 165);
  }
}

.e11-cursor-move {
  cursor: move;
}

.cdk-drag-preview {
  > div {
    background-color: aliceblue;
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5);
    cursor: move !important;
  }
}

.cdk-drag-preview {
  .community-nav-channel {
    border: 1px solid black;
    border-radius: 4px;
    background-color: rgb(160, 160, 160);
    color: rgb(100, 100, 100);
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5);
    cursor: move !important;
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating:not(.cdk-no-animation) {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
