// Stacking or all the z-index info
// Manage the stack order for the entire application in one location.
// This can or cannot include third party librairies but some overwrites may be needed here. Try to minimize

header {
  z-index: 100;
}

nav.navigation.app-left {
  z-index: 1;
}
