// Grid system
// Container widths
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;

  @media (min-width: $screen-xs-min) {
    width: $container-xs;
  }

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }

  @media (min-width: $screen-md-min) {
    width: $container-md;
  }

  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }

  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }

  @media (min-width: $screen-xxl-min) {
    width: $container-xxl;
  }
}

.container-90 {
  width: 90%;
  margin: 0 auto;
}

// Fluid container
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.
.container-fluid {
  @include container-fixed;
}

// Row
// Rows contain and clear the floats of your columns.
.row {
  @include make-row;
}

// Columns
// Common styles for small and large grid columns
@include make-grid-columns;

// Make grids (xs, sm, md, lg, xl)
@media (min-width: $screen-xs-min) {
  @include make-grid(xs);
}

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}

@media (min-width: $screen-xxl-min) {
  @include make-grid(xxl);
}
