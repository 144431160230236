.ais-Pagination {
  text-align: center;
  justify-content: center;
  display: flex;
  ul.ais-Pagination-list {
    .ais-Pagination-item {
      display: inline-block;
      a {
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color 0.3s;
        border: 1px solid lightgray;
        margin: 10px 0;
      }
    }
    .ais-Pagination-item--selected {
      a {
        color: $color-accent-blue;
        border: 1px solid $color-accent-blue;
        font-weight: 700;
        background-color: aliceblue;
      }
    }
  }
}

@media (max-width: 768px) {
  .ais-Pagination {
    ul.ais-Pagination-list {
      .ais-Pagination-item {
        a {
          font-size: 10px;
          padding: 4px 10px;
        }
      }
    }
  }
}
