/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #00000052 #ffffff00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000052;
  border-radius: 10px;
  border: none;
}
