// Theme - Skyblue
// The 11 unambiguous base colors but in more of a 'theme'
$color-red: #db4915;
$color-orange: #ff6500ff;
$color-yellow: #fce751;
$color-green: #417137;
$color-blue: #163c69; // Primary color
$color-purple: #96639c;
$color-pink: #c63088;
$color-brown: #694523;
$color-grey: #797979;
$color-black: #282828;
$color-white: #f8f9fa;

// globals - shared colors across themes
@import './../_globals/colors/base.scss';

// Tetradic-ish color palette
// 4 equally strong colors with one chosen as primary and one as secondary
// Two additional color thrown in for fun
$color-primary: $color-blue;
$color-secondary: $color-pink;
$color-three: $color-red;
$color-four: $color-green;
// Two additional for accents
$color-five: $color-purple;
$color-six: $color-grey;

// Semantic colors
// Bootstrap's standardized notification colors -----
// We can overwrite these to match the theme or keep these standardized colors
// $color-info: #17a2b8;
// $color-success: #28a745;
// $color-warning: #ffc107;
// $color-error: #dc3545;
// This theme's notification colors -----
$color-success: $color-green;
$color-info: $color-blue;
$color-alert: $color-yellow;
$color-warning: $color-orange;
$color-error: $color-red;
// $color-page-bg: #f7f7f7; // Exception for background - color from CNect comps
$color-page-bg: var(--color-app-bg); // Exception for background - color from CNect comps
$color-link: $color-primary;

$color-panel-border: $color-grey-drab;
$color-input-border: $color-grey-drab2;

// Variations
$color-blue-secondary: #3581cb;
$color-accent-blue: #0080d3;
$color-accent-green: #0bd899;
$color-blue-skyblue: #a3d6f7;

// Theme specific color helpers
.text-accent-blue {
  color: $color-accent-blue;
}
