// Text / Content
$color-content: #2d2d2d;
$color-content-dark: #111111;
$color-content-light: #505050;
$color-content-extralight: #767676;

// 50 shades of grey
$color-grey-one: #d0d0d0;
$color-grey-two: #f0f0f0;
$color-grey-three: #f7f7f7;
$color-grey-xxtralight: #ebebeb;
$color-grey-xtralight: #dcdad6;
$color-grey-light: #b2b3b2;
$color-grey-four: #9b9b9b;
$color-grey-five: #555555;
$color-grey-5d: #5d5d5d;
$color-grey-e8: #e8e8e8;
$color-grey-medium: #78787b;
$color-grey-drab: #dedede;
$color-grey-drab2: #bdbdbd;
$color-grey-dark: #3b3f45; // WCAG AA AAA compliant
$color-grey-darker: #43474c; // WCAG AA AAA compliant
$color-grey-dark-text-aa: #aaaaaa;
$color-grey-dark-text-aaa: #e7e7e7;
$color-grey-dark-text: #4a4a4a;
$color-grey-bg: #f7f7f7;
$color-grey-lines: #b7b7b7;
$color-grey-light: #f7f4f4;
$color-grey-dot: #eaeaea;

// Light
$color-white-off: #f7f7f7; // dark
$color-white-latte: #fffce8; // dark
$color-white-blush: #f9e9ec; // dark
$color-white-magnolia: #efe9f4; // dark
$color-white-stale: #f8f9fa;
$color-white-ivory: #fbfff1;
$color-tan-clad: #efece6;

$color-white-pure: #ffffff;
$color-grey-pure: #808080;
$color-black-pure: #000000;
