ul.ui-pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
}

ul.ui-pagination li {
  display: inline-flex;
  border: 1px solid $color-grey-lines;
  border-right: transparent;
  height: 3rem;
  width: 3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    opacity: 0.75;
  }

  &:last-child {
    border-right: 1px solid $color-grey-lines;
  }

  &:hover {
    background-color: $color-grey-light;
  }

  &.active {
    background-color: $color-primary;
    color: $color-white;
  }

  &.disabled {
    pointer-events: none;

    i {
      opacity: 0.25;
    }
  }
}
