// Catch-all for helper or presentation classes mainly used for UI quick/patch fixes or during prototyping
// Use these directly in the UI/HTML

// NOTE: This should be one of the FEW places we use an !important tag. Its use is HIGHLY discouraged
// The intent of these 'presentation' files is to force a class
/* stylelint-disable declaration-no-important */

.abstract {
  background-color: rgba(pink, 0.75);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic !important; // Why am I having to do this?
}

.pointer {
  cursor: pointer;
}

.underline-success {
  border-bottom: 2px solid $color-success;
}

.underline-warning {
  border-bottom: 2px solid $color-warning;
}

.underline {
  text-decoration: underline;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.nopadding-only {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nomargin-top {
  margin-top: 0 !important;
}

.margin-center {
  margin: 0 auto;
}

// Thinking about a mixin to create these util classes. Never needed them, only using 1...
// They will be misunderstood and misused
.margin-top-1 {
  margin-top: 1px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.nopadding-left {
  padding-left: 0;
}

.nopadding-right {
  padding-right: 0;
}

.nopadding-top {
  padding-top: 0;
}

.nopadding-bottom {
  padding-bottom: 0;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.text-white {
  color: $color-white !important;
}

.text-blue {
  color: $color-red !important;
}

.text-primary {
  color: $color-primary !important;
}

.text-secondary {
  color: $color-secondary !important;
}

.text-red {
  color: $color-red !important;
}

.text-grey {
  color: $color-grey-medium !important;
}

.text-grey-light {
  color: rgba($color-grey-medium, 0.7) !important;
}

.text-black {
  color: $color-black !important;
}

.text-success {
  color: $color-success;
}

.text-alert {
  color: $color-alert;
}

.text-warning {
  color: $color-warning;
}

// Mainly to get icons aligned vertically with text
.align-vert-middle {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.text-orange {
  color: $color-orange;
}

// Font size exceptions
.font-8px {
  font-size: 0.5333rem;
}

.font-9px {
  font-size: 0.6rem;
}

.font-10px {
  font-size: 0.6667rem;
}

.font-11px {
  font-size: 0.7333rem;
}

.font-12px {
  font-size: 0.8rem;
}

.font-13px {
  font-size: 0.8667rem;
}

.font-14px {
  font-size: 0.9333rem;
}

.font-15px {
  font-size: 1rem;
}

.font-16px {
  font-size: 1.067rem;
}

.font-18px {
  font-size: 1.2rem;
}

.font-20px {
  font-size: 1.333rem;
}

.font-22px {
  font-size: 1.467rem;
}

.font-24px {
  font-size: 1.6rem;
}

.font-26px {
  font-size: 1.733rem;
}

.font-28px {
  font-size: 1.867rem;
}

.font-30px {
  font-size: 2rem;
}

.font-32px {
  font-size: 2.133rem;
}

.font-34px {
  font-size: 2.267rem;
}

.font-36px {
  font-size: 2.4rem;
}

.font-38px {
  font-size: 2.533rem;
}

.font-40px {
  font-size: 2.667rem;
}

.h1-subtitle {
  display: block;
  font-size: 1.27rem;
  font-family: $font-primary;
  margin: 0.75rem 0 1.5rem;
}

.bold {
  font-weight: bold;
}

.timestamp {
  color: $color-grey-four;
  //font-family: $font-primary-light;
  font-size: 1rem;
  font-style: italic;
  line-height: 2.5rem;
}

.icon-20 {
  font-size: 1.333rem;
}

.icon-24 {
  font-size: 1.6rem;
}

.icon-32 {
  font-size: 2.133rem;
}

.icon-48 {
  font-size: 3.2rem;
}

.icon-64 {
  font-size: 4.267rem;
}

.icon-128 {
  font-size: 8.533rem;
}

.icon-xs {
  font-size: 1rem; // 15px
}

.icon-sm {
  font-size: 1.333rem; // 20 px
}

.icon-md {
  font-size: 1.6rem; // 24px
}

.icon-lg {
  font-size: 2.133rem; // 32px
}

.icon-xl {
  font-size: 3.2rem; // 48px
}

.icon-margin-right {
  margin-right: 10px;
}

.error,
.required {
  color: $color-error;
  // font-family: $font-primary;
}

.no-links {
  cursor: default;
  pointer-events: none;
}

.link {
  color: $color-primary;
  cursor: pointer;
  &.underlined {
    text-decoration: underline;
  }
}

.scroll-lock {
  overflow: hidden; // Prevent element from scrolling
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

// Spacers
.spacer-10 {
  height: 10px;
}

.spacer-20 {
  height: 20px;
}

.spacer-100 {
  height: 100px;
}

// By REM
.spacer-1rem {
  height: 1rem;
}

.spacer-2rem {
  height: 2rem;
}

.spacer-5rem {
  height: 5rem;
}

.height-100 {
  min-height: 100%;
}

// Dividers
hr {
  &.primary {
    height: 1px;
    width: 80%;
    margin: 0 auto;
    background-color: $color-primary;
  }

  &.secondary {
    height: 1px;
    width: 80%;
    margin: 0 auto;
    background-color: $color-secondary;
  }

  &.grey {
    height: 1px;
    width: 80%;
    margin: 0 auto;
    background-color: $color-grey-light;
  }

  // widths
  &.width-100 {
    width: 100%;
  }

  &.width-80 {
    width: 80%;
  }

  &.width-50 {
    width: 50%;
  }

  &.height-1rem {
    margin: 1rem auto;
  }

  &.height-2rem {
    margin: 2rem auto;
  }

  &.height-3rem {
    margin: 3rem auto;
  }

  &.height-4rem {
    margin: 4rem auto;
  }
}

.disabled-field {
  opacity: 0.4;
  position: relative;
}

.disabled-field::after {
  content: '';
  width: 100%;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  cursor: not-allowed;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.flex-vertical-center-justify {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.bg-grey-xtralight {
  background-color: $color-grey-xtralight;
}

.bg-grey-xxtralight {
  background-color: $color-grey-xxtralight;
}

.no-scale {
  transform: none !important;
}

.font-thin {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

// Add padding and margins
.margin-right-5 {
  margin-right: 5px;
}

.margin-right-1rem {
  margin-right: 1rem !important;
}

/* stylelint-enable declaration-no-important */

// Line height forcing
.line-height-3rem {
  line-height: 3rem;
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

ul {
  li {
    line-height: 1.5rem;
    // list-style: none;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Floats and clearfix
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.empty-state {
  color: $color-grey-medium;
  font-style: italic;
}
// Responsive helper assistants
// nopadding on one side means in smaller viewports you need to 'fix' (remove the pdding from) the other side.
// Fix for medium and large breakpoint
@media screen and (max-width: $screen-md-max) {
  .nopadding-left {
    padding-right: 0;
  }

  .nopadding-right {
    padding-left: 0;
  }

  .nopadding-middle-md {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .hidden-xs {
    display: none;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .hidden-sm {
    display: none;
  }
}

@media screen and (max-width: $screen-md-max) {
  .hidden-md {
    display: none;
  }
}

@media screen and (max-width: $screen-lg-max) {
  .hidden-lg {
    display: none;
  }
}

// Dont see a reason to hide anything on xl
