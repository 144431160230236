// Import needed variables
@import 'projects/shared-lib/src/assets/stylesheets/themes/darkblue/typography';

.panel-divided {
  border-top: 1px solid $color-input-border;
  width: calc(100% + 3.75rem);
  margin-left: -1.875rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
