// Import needed variables
@import 'projects/shared-lib/src/assets/stylesheets/themes/darkblue/typography';

.container-items {
  position: relative;
  min-height: 20rem;

  .container-slider {
    position: absolute;
    display: flex;
    width: 100%;
    overflow-x: auto;
    opacity: 0;
    transition: opacity 0.4s ease-in-out 0s, z-index 0s linear 0.4s;

    &.active {
      top: 0;
      opacity: 1;
      z-index: 1;
      transition: opacity 0.4s ease-in-out 0s;
    }
  }

  .item-card {
    display: flex;
    flex-direction: column;
    width: 21rem;
    min-width: 21rem;
    max-width: 21rem;
    min-height: 18rem;

    margin-right: 20px;
    border-radius: 12px;
    cursor: pointer;

    .header {
      background-color: $color-accent-blue;
      font-weight: 600;
      padding: 1rem;
      // color: $color-white-pure;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .body {
      border: 1px solid $color-input-border;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: space-between;
      overflow: auto;
      margin-bottom: 0.625rem;
      .title {
        margin-bottom: 0.33rem;
      }
      .description {
        min-height: 5rem;
        max-height: 7rem;
        overflow: auto;
        line-height: 1.5rem;
      }
      &:hover {
        cursor: pointer;
        // box-shadow: 1px 1px 6px rgba($color-black, 0.2);
        background-color: aliceblue; // Love that aliceblue with the cnect colors
      }
    }

    // &:hover {
    //   .header {
    //     background-color: darken($color-accent-blue, 5%);
    //   }
    //   cursor: pointer;
    //   box-shadow: 1px 1px 6px rgba($color-black, 0.2);
    //   background-color: aliceblue; // Love that aliceblue with the cnect colors
    // }
  }

  .container-add {
    position: absolute;
    top: 315px;
    opacity: 0;
    z-index: -1;
    width: 100%;
    background-color: $color-white-pure;
    padding: 1rem;
    transition: top 0.4s 0s, z-index 0s linear 0.4s, opacity 0.4s linear 0s;
    transition-timing-function: cubic-bezier(1, -0.5, 0.33, 1.5);

    &.active {
      top: 0px;
      opacity: 1;
      z-index: 2;
      transition: top 0.4s 0s, z-index 0s linear 0s, opacity 0.4s linear 0s;
      transition-timing-function: cubic-bezier(0.48, -0.5, 0.33, 1.5);
    }
  }
}

// Keep, may be used later
// .items-container {
//   height: 100%;

//   .items {
//     height: 64vh;
//     overflow-y: scroll;
//     padding-right: 2rem;
//     border-right: 1px solid $color-input-border;

//     .item {
//       cursor: pointer;
//       padding-bottom: 2rem;
//       margin-bottom: 2rem;
//       border-bottom: 1px solid $color-input-border;

//       &:hover {
//         h3 {
//           color: $color-blue-secondary;
//         }

//         color: $color-blue-secondary;
//       }
//     }
//   }

//   .item-detail {
//     display: block;
//     position: relative;
//     padding: 0 0 0 1.5rem;
//   }
// }
