@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// This base file determines the current theme. Go to that file to change themes
@import 'themes/base';

// Grid system
// The grid system is dependant on the mixins that generate the code to be used
@import 'mixins/grid';
@import 'mixins/grid-framework';
@import 'mixins/svg-charts';
@import 'mixins/svg-tool-tips';
@import 'utils/grid-system';

// Presentation styles - These are 'helper' classes that are implemented in HTML as opposed to in-line styles
@import 'presentation/flex';
@import 'presentation/helpers';
@import 'presentation/ordered-list';

// Utils are things that make life a bit easier... NOTE: These depend on brand colors so colors will have to be defined
// @import 'utils/pre';
@import 'utils/tabs';
@import 'utils/tabs-new';
@import 'utils/terminal';
@import 'utils/error-handling';
@import 'utils/media-query';
@import 'utils/pagination';
@import 'utils/drag-drop';
@import 'utils/lists';
@import 'utils/scrollbar';
// @import 'utils/table';

// Components - Or things that are globally shared and should be made into components
@import 'components/card-slider-line-item';
@import 'components/panels';
@import 'components/pagination';

// Theme(s)
@import 'node_modules/@engineering11/ui-theme-cnect/stylesheets/theme';

// E11 Component Styles
@import 'node_modules/@engineering11/ui-lib/assets/stylesheets/components/base.scss';
@import 'node_modules/@engineering11/multimedia-web/src/assets/style.scss';

// Material icon sizes
@import 'node_modules/@engineering11/ui-lib/assets/stylesheets/fonts/base.scss';

// Boom! - ScrollLock - Needs a better home, maybe
.scroll-lock {
  overflow: hidden; // Prevent element from scrolling
}

// Find me a better home
.e11-word-break {
  word-break: break-all;
}

.e11-size-ginormous {
  font-size: 18rem;
}

// Media Queries - @Todo: Globalize
@media (max-width: 768px) {
  .e11-size-ginormous {
    font-size: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .e11-size-ginormous {
    font-size: 12rem;
  }
}

// Skeleton loaders for text
h1:empty {
  height: 42px;
}
h2:empty {
  height: 36px;
}
h3:empty,
h3.e11-h3-skeleton {
  height: 25px;
}

h4:empty {
  height: 20.8px;
}
h5:empty {
  height: 20.8px;
}
h6:empty {
  height: 20.8px;
}
p:empty,
span:empty.e11-skeleton-empty,
div:empty.e11-skeleton-empty,
p.e11-p-skeleton {
  height: 20.8px;
}
h1:empty,
h2:empty,
h3:empty,
h3.e11-h3-skeleton,
h3:empty.e11-skeleton-empty,
h4:empty,
h5:empty,
h6:empty,
p:empty,
span:empty.e11-skeleton-empty,
div:empty.e11-skeleton-empty,
p.e11-p-skeleton {
  width: 50%;
  background: #dddddd;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

// .e11-chart-percentage-skeleton {
//   display: flex;
//   height: 110px;
//   width: 110px;
//   border-radius: 50%;
//   background: #dddddd;
//   animation: pulse 1.5s infinite;
// }

.e11-panel-skeleton {
  display: flex;
  height: 100%;
  min-height: 200px;
  max-height: 160px;
  width: 100%;
  background: #dddddd;
  animation: pulse 1.5s infinite;
}

.e11-button-pill-sm-skeleton {
  display: flex;
  height: 32px;
  width: 130px;
  border-radius: 16px;
  background: #dddddd;
  animation: pulse 1.5s infinite;
}

/* Animation */
@keyframes pulse {
  0% {
    background-color: #dddddd;
  }
  50% {
    background-color: #eeeeee;
  }
  100% {
    background-color: #dddddd;
  }
}

// Skeleton: Exceptions and issues
// Maintain this shared list of exceptions as we implement skeleton loaders.
// This will act as documentation to be used to fix the issues below

// ngx-markdown inserts an empty paragraph for seemingly no reason...
.e11-ui-message {
  markdown {
    p:empty {
      display: none;
    }
  }
}
.e11-loading-dots {
  h3:empty {
    &.e11-loading-dots-title {
      display: none;
    }
  }
}
